<template>
  <v-navigation-drawer
    fixed
    clipped
    app
    v-model="drawer"
    class="overflow-y-auto"
  >
    <v-list>
      <v-list-item v-if="websites" class="module">
        <!-- Show dropdown when there are multiple websites -->
        <v-select
          v-if="websites.length > 1"
          prepend-inner-icon="mdi-laptop"
          :items="websites"
          :value="selectedWebsite"
          :label="$t('Select a module')"
          v-model="defaultSelected"
          single-line
          menu-props="bottom"
          item-value="_id"
          item-text="Name"
          @change="setSelectedWebsite(defaultSelected)"
          class="websiteSelect"
          hide-details
          outlined
          dense
        ></v-select>
        <!-- Show label when there is only one website -->
        <p
          class="mb-0 text-h6 font-weight-bold text-primary"
          v-if="websites.length == 1"
        >
          {{ websites[0].Name }}
        </p>
      </v-list-item>
      <template v-if="selectedWebsite && currentLanguage">
        <v-list-item v-if="checkForPages()" class="px-2 mt-2 mb-4">
          <v-slide-x-transition>
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              :label="$t('Search')"
              clearable
              v-model="navSearchValue"
              outlined
              dense
              single-line
              hide-details="auto"
            ></v-text-field>
          </v-slide-x-transition>
        </v-list-item>
      </template>
    </v-list>
    <template v-if="selectedWebsite && currentLanguage">
      <v-list nav dense class="py-0">
        <v-list-item-group color="activemenucolor" v-model="selectedPage">
          <div v-for="(page, index) in selectedWebsiteSortedPages" :key="index">
            <v-list-item
              v-if="page.SubPages.length > 0"
              :to="
                page.Group
                  ? null
                  : { name: 'editPage', params: { id: page._id.$oid } }
              "
              :class="page.Group ? 'menu-item-hover-group mb-1' : 'mb-1'"
            >
              <v-row
                no-gutters
                class="d-flex align-center justify-space-between"
              >
                <v-col cols="10">
                  <v-layout fill-height>
                    <v-list-item-icon v-if="page.Icon" class="my-0 mr-4" active>
                      <v-icon class="menu-icon">{{ page.Icon }}</v-icon>
                    </v-list-item-icon>
                    <div class="pl-10" v-else>
                      <v-spacer></v-spacer>
                    </div>
                    <v-list-item-title
                      v-if="page.Name[currentLanguage].length == 0"
                      class="text-truncate maxtext-width"
                    >
                      {{ page.Name[Object.keys(page.Name)[0]] }}
                    </v-list-item-title>
                    <v-list-item-title
                      v-else
                      class="text-truncate maxtext-width"
                    >
                      {{ page.Name[currentLanguage] }}
                    </v-list-item-title>
                  </v-layout>
                </v-col>
                <v-col cols="2">
                  <template v-if="checkForIconUpDown(page)">
                    <v-btn icon @click.prevent="slideOpen(index)">
                      <v-icon>
                        {{
                          openPage[index]
                            ? "mdi-chevron-up"
                            : "mdi-chevron-down"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item
              v-else
              @click="goToRoute('editPage', page._id.$oid, page, index)"
              class="mb-1"
            >
              <v-layout>
                <v-list-item-icon class="my-0 mr-4" v-if="page.Icon">
                  <v-icon class="menu-icon">{{ page.Icon }}</v-icon>
                </v-list-item-icon>
                <div class="pl-10" v-else>
                  <v-spacer></v-spacer>
                </div>
                <v-list-item-title
                  v-if="page.Name[currentLanguage].length == 0"
                  class="text-truncate maxtext-width"
                >
                  {{ page.Name[Object.keys(page.Name)[0]] }}
                </v-list-item-title>
                <v-list-item-title v-else class="text-truncate maxtext-width">
                  {{ page.Name[currentLanguage] }}
                </v-list-item-title>
              </v-layout>
            </v-list-item>
            <v-expand-transition>
              <v-sheet v-if="openPage[index]" height="auto">
                <div
                  :key="'sub-pages-container' + page._id.$oid"
                  v-if="page.SubPages.length > 0"
                >
                  <div
                    v-for="(subPage, i) in getSubPages(page.SubPages)"
                    :key="i"
                  >
                    <v-list-item
                      v-if="subPage.Enabled"
                      :key="subPage._id.$oid"
                      :to="{
                        name: 'editPage',
                        params: { id: subPage._id.$oid },
                      }"
                      subheader
                      class="iconPadding max-hight"
                      color="activemenucolor"
                    >
                      <v-list-item-icon
                        class="my-2 mr-4"
                        v-if="getSubpageIcon(subPage._id.$oid) != ''"
                      >
                        <v-icon class="menu-icon">{{
                          getSubpageIcon(subPage._id.$oid)
                        }}</v-icon>
                      </v-list-item-icon>
                      <div class="pl-10" v-else>
                        <v-spacer></v-spacer>
                      </div>
                      <v-list-item-title>
                        {{ getNameFromPageId(subPage._id.$oid) }}
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                </div>
              </v-sheet>
            </v-expand-transition>
          </div>
        </v-list-item-group>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      defaultSelected: "",
      navSearchValue: "",
      openPage: [],
      selectedPage: null,
    };
  },
  computed: {
    ...mapGetters({
      selectedWebsite: "selectedWebsite",
      websites: "allWebsites",
    }),
    drawer: {
      get() {
        return this.$store.getters.drawer;
      },
      set(value) {
        this.$store.dispatch("setDrawer", value);
      },
    },
    userIsAdministrator() {
      return this.$auth.userIsAdministrator();
    },
    userIsUserManager() {
      return this.$auth.userIsUserManager();
    },
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    selectedWebsiteSortedPages: {
      get: function () {
        this.setDefaultSelected(this.selectedWebsite);
        const sortedWebsites = this.selectedWebsite.Pages.slice().sort(
          this.$helpers.sortByKey(this.currentLanguage)
        );
        let allWebsitePages = [];
        sortedWebsites.forEach((element, index) => {
          const checkName = element.Name[this.currentLanguage]
            .toLowerCase()
            .includes(
              this.navSearchValue ? this.navSearchValue.toLowerCase() : ""
            );
          element.SubPages.forEach((subpage) => {
            if (
              this.$route.params.id == subpage.$oid &&
              !Object.prototype.hasOwnProperty.call(this.openPage, index)
            ) {
              this.$set(this.openPage, index, 1);
            }

            if (checkName && element.IsSubPage) {
              if (
                subpage.$oid == element._id.$oid &&
                !allWebsitePages.filter((e) => e.Name != element.Name).length >
                  0
              ) {
                if (
                  this.navSearchValue
                    ? this.navSearchValue.toLowerCase()
                    : "" == element.Name[this.currentLanguage].toLowerCase()
                ) {
                  let pageCopy = Object.assign({}, element);
                  pageCopy.SubPages = pageCopy.SubPages.filter(
                    (el) => el.$oid == subpage.$oid
                  );
                  allWebsitePages.push(pageCopy);
                } else {
                  allWebsitePages.push(element);
                }
              }
            }
          });
          if (checkName && element.Enabled && !element.IsSubPage) {
            allWebsitePages.push(element);
          }
        });
        const allFilterdWebsitePages = allWebsitePages.filter(
          (v, i, a) => a.indexOf(v) === i
        );

        this.setSelectedPageActive(
          allFilterdWebsitePages.findIndex(
            (obj) => obj._id.$oid === this.$route.params.id
          )
        );
        return allFilterdWebsitePages;
      },
    },
  },
  methods: {
    setSelectedPageActive(indexOfPage) {
      if (indexOfPage < 0) return;
      this.selectedPage = indexOfPage;
    },
    checkForPages() {
      const subpages = this.selectedWebsite.Pages.filter(
        (el) => el.IsSubPage == true && el.Enabled == true
      );

      const pagesWithoutSubPages =
        this.selectedWebsite.Pages.filter((el) => el.Enabled == true).length -
        subpages.length;
      return pagesWithoutSubPages > 11;
    },
    setDefaultSelected(value) {
      this.$store.dispatch("setSelectedWebsite", value);
      this.defaultSelected = value;
    },
    goToRoute(name, pageId, page) {
      if (page["Group"]) {
        return;
      }
      if (this.$route.params.id != pageId) {
        this.$router.push({
          name: name,
          params: { id: pageId },
        });
        this.$router.go(1);
      }
    },
    checkForIconUpDown(page) {
      const sortedWebsites = this.selectedWebsite.Pages.slice().sort(
        this.$helpers.sortByKey(this.currentLanguage)
      );
      let hasAccess = false;
      page.SubPages.forEach((el) => {
        if (sortedWebsites.find((e) => e._id.$oid == el.$oid)) {
          hasAccess = true;
        }
      });

      return hasAccess;
    },
    slideOpen(index) {
      this.$set(this.openPage, index, !this.openPage[index]);
    },
    getSubPages(subpages) {
      let pages = [];
      subpages.forEach((element) => {
        let page = this.selectedWebsite.Pages.find(
          (p) => p._id.$oid === element.$oid
        );
        if (page) {
          pages.push(page);
        }
      });

      pages = pages.sort(this.$helpers.sortByKey(this.currentLanguage));
      return pages;
    },
    getSubpageIcon(id) {
      return this.selectedWebsite.Pages.find((p) => p._id.$oid === id).Icon;
    },
    getNameFromPageId(id) {
      return this.selectedWebsite.Pages.find((p) => p._id.$oid === id).Name[
        this.currentLanguage
      ];
    },
    setSelectedWebsite(value) {
      this.$store.dispatch("setSelectedWebsiteById", value);
      const selectedWebsiteSortedPagesEnabled =
        this.selectedWebsiteSortedPages.filter((page) => page.Enabled == true);
      this.$router.push({
        name: "editPage",
        params: { id: selectedWebsiteSortedPagesEnabled[0]._id.$oid },
      });
      this.$router.go(1);
    },
  },
};
</script>

<style scoped>
.module {
  padding: 0 8px 0 8px;
}
.websiteSelect >>> .v-input__prepend-inner {
  margin-right: 12px;
}
.websiteSelect >>> .v-input__slot {
  padding: 0 8px !important;
}

.menu-item-hover {
  background: #ddd;
  cursor: pointer;
}
.menu-item-hover-group {
  cursor: default !important;
}
.router-link-active .menu-icon {
  color: var(--v-primaryColor-base) !important;
}
.maxtext-width {
  max-width: 150px;
}
.iconPadding {
  margin: 0 0rem 0rem 2.5rem;
}
.max-hight {
  min-height: 32px !important;
}
@media screen and (min-width: 820px) {
  .maxtext-width {
    max-width: 175px;
  }
}
</style>